<template>
  <div>
    <v-img position="center center" height="300" :src="require('@/assets/backgrounds/seattle1.jpeg')">
      <!-- <v-row align="end" justify="end" style="height:400px" class="pt-0 mt-0">
        <v-col cols="12" class="pb-0 mb-0 mt-0 pt-0">
          <v-card class="pl-0 ml-0 mt-0 pt-0" color="rgba(256, 256, 256, 0.9)" tile>
            <CenteredContent>
              <template v-slot:title></template>
              <template v-slot:content>
                <p>
                  I have enjoyed building, improving, and fixing things for as long as I
                  can remember. Recently (the past decade) I have focused this energy on
                  <span class="text-decoration-underline">Building Software & Building Teams that Build Software</span>
                </p>
              </template>
            </CenteredContent>
          </v-card>
        </v-col>
      </v-row> -->
      <v-overlay opacity="0.8" absolute/>
    </v-img>
    <CenteredContent>
      <template v-slot:title>More about me</template>
      <template v-slot:content>
        <p>
          I have enjoyed building, improving, and fixing things for as long as I
          can remember. Recently (the past decade) I have focused this energy on
          <span class="text-decoration-underline">Building Software & Building Teams that Build Software</span>
        </p>
      </template>
    </CenteredContent>
  </div>
</template>

<script>
export default {
  name: "About",

  data: () => ({})
};
</script>
