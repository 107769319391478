<template>
  <CenteredContent>
    <template v-slot:title>Contact</template>
    <template v-slot:content>
      <v-row class="mb-1" dense>
        <v-btn href="mailto:sobotkagregory@gmail.com" block dark>
          EMAIL
        </v-btn>
      </v-row>
      <ContactInformation />
    </template>
  </CenteredContent>
</template>

<script>
import ContactInformation from "@/components/ContactInformation";
export default {
  name: "Contact",
  components: {
    ContactInformation
  },
  data: () => ({})
};
</script>
