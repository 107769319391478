<template>
  <v-row>
    <v-spacer />
    <v-col cols="11" md="8" xl="4" class="px-4">
      <v-row
        v-for="domain in experience.domains"
        v-bind:key="domain.name"
        class="my-6"
      >
        <v-col
          cols="2"
          sm="12"
          md="12"
          lg="2"
          class="pb-2 text-md-left text-lg-right"
        >
          <h6 class="text-h6">{{ domain.name }}</h6>
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          v-for="detail in domain.details"
          v-bind:key="detail.title"
          class="py-0"
        >
          <v-row align="center">
            <v-col cols="2" class="py-0">
              <p class=" text-caption text-right my-0">
                {{ detail.title }}
              </p>
            </v-col>
            <v-col cols="10" class="py-0">
              <v-progress-linear
                color="light-blue"
                height="12"
                class="my-0"
                :value="detail.level"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
export default {
  name: "Experience",
  components: {},
  data: () => ({
    experience: {
      domains: [
        {
          name: "Cloud & Infrastructure",
          details: [
            {
              title: "AWS",
              specifics: "",
              level: 75
            },
            {
              title: "Kubernetes",
              specifics: "",
              level: 50
            },
            {
              title: "Google Cloud Platform",
              specifics: "",
              level: 33
            },
            {
              title: "Terraform",
              specifics: "",
              level: 25
            }
          ]
        },
        {
          name: "Python",
          details: [
            {
              title: "FastAPI",
              specifics: "",
              level: 60
            }
          ]
        },
        {
          name: "Nodejs",
          details: [
            {
              title: "Express",
              specifics: "",
              level: 60
            }
          ]
        },
        {
          name: "Auth",
          details: [
            {
              title: "SSO Integrations",
              level: 40
            }
          ]
        }
      ]
    }
  })
};
</script>
